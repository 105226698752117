<template>
  <v-container>
    <Loader v-if="this.appLoading.status" :opaque="this.appLoading.opaque" />
    <v-row v-else>
      <v-col cols="12" lg="6">
        <v-img alt="user" :src="logo"></v-img>
      </v-col>
      <v-col align="center" cols="12" lg="6">
        <v-img
          v-if="snackbar"
          alt="user"
          height="90"
          width="90"
          :src="logo2"
        ></v-img>
        <router-link
          v-if="snackbar"
          style="text-decoration: none; color: inherit"
          to="/login"
          ><v-btn color="#113c57" dark rounded>
            Iniciar sesión
          </v-btn></router-link
        >
      </v-col>

      <v-row>
        <v-col
          cols="6"
          sm="6"
          class="pr-0 mb-5"
          v-for="item in views"
          :key="item.id"
        >
          <v-card
            color="primary"
            dark
            @click="whereToPush(item)"
            height="100px"
          >
            <v-card-title class="text-h5">
              {{ item.title }}
            </v-card-title>

            <v-card-subtitle>{{ item.subtitle }} </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Loader from "../components/ui/Loader.vue";
import axios from "@/axios/axios-api";
import enums from "@/utils/enums/index.js";

export default {
  name: "Home",
  components: {
    Loader,
  },
  async created() {
    this.setPermisos();
    this.views.sort(function (a, b) {
      return a.order - b.order;
    });
  },
  data() {
    return {
      logo: require("../assets/LogoSinTextoIncidentes.png"),
      logo2: require("../assets/incidenteslogo.png"),
      options: null,
      views: [],
      optionCode: enums.webSiteOptions.INCIDENTES,
      allowedActions: null,
    };
  },
  methods: {
    async setPermisos() {
      this.options = (
        await axios.get("api/webSiteOptions/options")
      ).data.options;

      // viene como "observer" asi que lo fuerzo a setearse como viene
      const options = JSON.parse(JSON.stringify(this.options));
      options?.forEach((x) => {
        x.children.forEach((element) => {
          switch (element.optionCode) {
            case enums.webSiteOptions.USUARIOSYPERMISOS:
              this.views.push({
                id: 1,
                title: "Usuarios",
                order: 1,
                subtitle: "ABM de usuarios y la configuración de permisos.",
                path: "usuarios",
              });
              break;
            case enums.webSiteOptions.CLIENTES:
              this.views.push({
                id: 2,
                title: "Clientes",
                order: 1,
                subtitle:
                  "Consulta de clientes y su configuración de usuarios y datos de acceso.",
                path: "clientes",
              });
              break;
            case enums.webSiteOptions.ENVIODATOSCLIENTES:
              this.views.push({
                id: 3,
                title: "Envío datos clientes",
                order: 1,
                subtitle:
                  "Consulta de los datos enviados por los clientes según lo configurado en cada caso.",
                path: "EnvioDatosClientes",
              });
              break;
            case enums.webSiteOptions.LICENCIASYCLIENTES:
              this.views.push({
                id: 4,
                title: "Licencias de clientes",
                order: 1,
                subtitle:
                  "Consulta y asignación de las fechas de licencia de cada cliente.",
                path: "LicenciasClientes",
              });
              break;
            case enums.webSiteOptions.FERIADO:
              this.views.push({
                id: 5,
                title: "Feriados",
                order: 5,
                subtitle: "ABM de feriados.",
                path: "Feriados",
              });
              break;
            case enums.webSiteOptions.DOBLE_FACTOR_AUTENTICACION:
              this.views.push({
                id: 6,
                title: "Doble factor autenticación",
                order: 6,
                subtitle:
                  "Permite configurar el doble factor de autenticación, general y por usuario, para el inicio de sesión.",
                path: "DobleFactorAutenticacion",
              });
              break;
          }
        });
      });
    },
    whereToPush(item) {
      this.$router.push({
        name: item.path,
      });
    },
  },
  computed: {
    ...mapState({
      user: "user",
    }),
    ...mapGetters({
      appLoading: "user/appLoading",
    }),
    snackbar() {
      // getUserService
      const userLogged = this.user.user;
      if (userLogged && Object.keys(userLogged).length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
